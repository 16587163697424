// Ecoboil Main Sass
@import "custom";
@import "bootstrap";

.text-centre {
    @extend .text-center;
}

/**
 * Global
 */
::selection {
 	background: #C60;
 	color: #fff;
 	text-shadow: none;
}

body a:link {
    -webkit-tap-highlight-color: $primary-darker;
    -webkit-tap-highlight-color: transparent; /* For some Androids */
}

.container-fluid {
 	@extend .container-fluid;
 	padding-left: 30px;
 	padding-right: 30px;
}

 // no underline when hovering
a:hover {
 	text-decoration: none;
}

a:active, a:focus {
 	outline: none;
    color: shift-color($link-color, 25%);
}


// Clear placeholder upon focus
// Ref: https://stackoverflow.com/questions/9707021/how-do-i-auto-hide-placeholder-text-upon-focus-using-css-or-jquery
::placeholder { opacity: 1; transition: opacity .35s; }
*:focus::placeholder { opacity: 0 !important; }

/*
 * start Typography & Headings
 */
.page-header {
	margin: 0 0 20px;
}
div.pure-filters h1, div.pure-filters h2, div.pure-filters h3, div.pure-filters h4, div.pure-filters h5, div.pure-filters h6 { color: $brand-purify; }
div.pure-filters h4.panel-title  { color: $brand-purify;}

/*
 * finish Typography
 */


/*
 * start Layout
 */

 // Sticky footer reference: http://getbootstrap.com/examples/sticky-footer/

html {
 	// Sticky footer settigs
    @include media-breakpoint-up(sm) {
        position: relative;
 		min-height: 100%;
    }
}

body {
 	// sticky footer settings
    @include media-breakpoint-up(sm) {
        margin-bottom: $stickyFooterHeight;
    }
}

main {
 	margin-top: $gapBetweenNavAndMain;
 	padding-bottom: 1px;
}

footer {
 	padding-top: 20px;
 	background: $charcoal;
 	color: $white;

 	a {
 		color: $white;
 		font-size: 12px;
 	}

 	a:hover {
  	   color: darken($white, 7.5%);
 	}

 	a:active{
 		color: darken($white, 15%);;
 	}

 	// sticky footer settings
    @include media-breakpoint-up(sm) {
        position: absolute;
 		bottom: 0;
 		width: 100%;
 		height: $stickyFooterHeight;         /* same as the body margin-bottom */
    }

    h4 {
		margin-bottom: 3px;
	}

    li.about {
        padding-top: 20px;
    }

    // References: http://css-tricks.com/equidistant-objects-with-css/, http://stackoverflow.com/questions/6865194/fluid-width-with-equally-spaced-divs
   .footer-copyright {
       padding-left: 17px;
       padding-right: 17px;
       padding-bottom: 20px;
   }
}

/* -- finish Layout -- */



/**
 * start Forms
 */

 /* ==========================================================================
    Buttons
    ========================================================================== */
.btn {
    appearance: none;
    color: $white;
}   // Reference: double button in iPhone https://stackoverflow.com/questions/5438567/css-submit-button-weird-rendering-on-ipad-iphone
.btn-primary {
    &:hover {
        background-color: $primary-dark !important;
        border-color: darken($primary, 7.5%) !important;
        color: darken($white, 7.5%) !important;
    }

     // selected button
    &:focus {
        background-color: darken($primary, 3%) !important;
        border-color: darken($primary, 4%) !important;
        color: darken($white, 4%);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    }

     // colour when you click
    &:active {
        background-color: $primary-darker !important;
        border-color: darken($primary, 15%) !important;
        color: darken($white, 15%) !important;
    }

    &:disabled {
        color: darken($white, 35%) !important;
    }
}

.btn-group {
    label.active {
        background-color: darken($primary, 3%) !important;
        border-color: darken($primary, 4%) !important;
        color: darken($white, 4%);
    }
}

input[type="submit"] {
    color: $white !important;
}

#navbar-toggle.navbar-toggle{
    padding: 12px 10px;
    margin-top: 14px;

    .icon-bar {
        height: 2px;
    }

    &:hover {
        background-color: $primary-dark !important;
        border-color: #375215;
    }

     // selected button
    &:focus {
        background-color: $primary !important;
        outline-color: $primary;
    }

    // colour when you click
    &:active {
        background-color: $primary-darker !important;
    }
}

.btn-warning {
    @extend .btn-warning;

    // selected button
    &:focus {
        background-color: $warning !important;
        outline-color: $warning;
    }

    // colour when you click
    &:active {
        background-color: shift-color($warning, 20%) !important;
    }
}

// Forms
div.field.text, div.field.knockoutdropdown {   // Wrapper around input fields
    @extend .row;
    @extend .mb-3;
}

input[type="text"], input[type="email"], input[type="number"], input[type="password"], textarea {
    @extend .form-control;
    :focus {
        background-color: white !important;
    }
    line-height: 1.5;
}

input[type="text"]:focus, input[type="email"]:focus, input[type="password"]:focus, input[type="number"]:focus, textarea:focus {
    box-shadow: 0 0.125rem 0.25rem $primary !important;
}

// Numeric input
input[type="number"] {
    @extend .form-control-lg;
    text-align: center;
    width: 80px !important;
}

input[type="search"]{
    height: inherit;  // align search box with associated button
     &:focus{
        border-color: #ccc;
    }
}

input[name="Email"], input[type="email"]{
    text-transform: lowercase;
}

// checkboxes & radios
ul.optionset li {
    @extend .form-check;
    input[type="radio"] {
        @extend .form-check-input;
    }
    label {
        @extend .form-check-label;
    }
}

// Dropdown
.field.knockoutdropdown {
    @extend .form-floating;
    margin-left: 2px;
    select {
        @extend .form-select;
        @extend .w-auto;
    }
    select:focus {
        box-shadow: 0 0.125rem 0.25rem $primary !important;
    }
}

// Select
select.form-select {
    width: fit-content;
}

// Toolbar Buttons
.btn-toolbar, .Actions {
    margin-top: 15px;
    margin-bottom: 15px;
    input { @extend .btn; }
    input[type="submit"] {
        @extend .btn-primary;
    }
}

.btn-toolbar.large, .Actions.large {
    input { @extend .btn; }
    input[type="submit"] {
        @extend .btn-primary;
        @extend .btn-lg;
    }
}

 // Messages
label.required, input.required, select.required, textarea.required, span.required { color: $danger; }
input.required, input:invalid, select.required, select:invalid, textarea.required, textarea:invalid, .field.knockoutdropdown select:invalid {
    border-color: $form-feedback-invalid-color !important;
    box-shadow: (inset 0 2px 2px rgba(0,0,0,.075)) !important; // Redeclare so transitions work
    &:focus {
         border-color: shift-color($required, 10%) !important;
         box-shadow: (inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px 0.1rem tint-color($required, 20%)) !important;
    }
}

// Required
input:valid, .field.knockoutdropdown select:valid {
    border-color: $form-feedback-valid-color;
}
/*   --- end form --- */



/*   ----  start Main    --- */

/* 	==========================================================================
	 Navs
	========================================================================== */
// hide and show upon collapse
.non-collapse-show {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

// Mobile Navigation
#navbar .btn-navbar-mobile {
     // mobile navigation text and button size
     // match to .navbar-nav-custom class in main.less
    @include media-breakpoint-down(sm) {
        color: $btn-navbar-mobile-color;
        background-color: $btn-navbar-mobile-bg;
        border-color: $btn-navbar-mobile-border;

        &:hover {
            color: $btn-navbar-mobile-bg;
            background-color: $btn-navbar-mobile-color;
            border-color: $btn-navbar-mobile-color;
        }
        &:active,
        &.active, .open > .dropdown-toggle {
            box-shadow: none;
            color: shift-color($btn-navbar-mobile-bg, 25%);
            background-color: shift-color($btn-navbar-mobile-color, 25%);
            border-color: shift-color($btn-navbar-mobile-color, 25%);
        }
        &:active,
        &.active, .open > .dropdown-toggle {
            background-image: none;
        }
        outline: 0;  // firefox
        border-radius: 0;
        vertical-align: inherit;
        font-size: 18px;
        line-height: 31px;
    }
}

.navbar-dark {
    .navbar-nav {
        @include media-breakpoint-down(md) {  //Mobile
            li {
                padding-left: .75rem;
            }
            li.nav-item.current {
                a.nav-link {
                    color: $grey-400;
                }
        	}
            li.nav-item.current {
        		background-color: $secondary !important;
                a.nav-link {
                    color: $white !important;
                }
        	}
            li.mobile-hover:hover, li.mobile-hover:focus, li.mobile-hover.current:hover, li.mobile-hover.current:focus {
                background-color: $grey-200;
                a.nav-link {
                    color: $primary;
                }
            }
            li.mobile-hover:active, li.mobile-hover.current:active {
                background-color: $grey-300;
                a.nav-link {
                    color: $secondary;
                }
            }
            .navbar-icon {
                span {
                    line-height: 1.8;
                    color: white;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }

            .navbar-icon:hover {
                background-color: $grey-200;
                span {
                    color: $primary;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }
            .navbar-icon:active {
                background-color: $grey-300;
                span {
                    color: $secondary-dark;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                }
            }
        }
        // Non Collapse
        @include media-breakpoint-up(md) {
            li.nav-item.current {
                a.nav-link {
                    color: $grey-400;
                }
        	}
            li:hover, li.nav-item.current:hover {
                a.nav-link {
                    color: darken($grey-400, 7.5%);
                }
            }
            li:active, li.nav-item.current:active {
                a.nav-link {
                    color: darken($grey-400, 15%);
                }
            }
        }
    }
}

// Mobile Toggle Size
.navbar-toggler-icon {
    width: 2em;
    height: 2em;
}

.search-form {
	padding-right: 0;
	margin-right: 0;
    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        .search-form-input {
            width: 60px;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 0;
    }
}

// Logo
.logo {
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 6px;
	@extend .float-start;
    @include media-breakpoint-up(sm) {
        position: absolute;
        padding-top: 10px;
        padding-left: 0;
    }
}

/** Mega Menu & icons  **/
.icon-mega-menu {
	color: $body-colour;
	font-size: 58px;
  @include media-breakpoint-down(md) {
    font-size: 30px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 58px;
  }
}

.navigation-mega-card-header {
  height:73px;
  @include media-breakpoint-down(sm) {
    .card-title {
      font-size: .60rem;
    }
  }
}

.icon.icon-nav:before {
	color: inherit;
	line-height: 20px;
	font-size: 20px;
}

.icon.icon-sidebar:before {
	color: inherit;
	line-height: 20px;
	font-size: 17px;
}

.icon-cart.icon-minus, .icon-cart.icon-plus, .icon-cart.icon-cancel.cartpage {
	font-size: 8px;
	line-height: 2;
}


/* 	==========================================================================
	 Sidebar Menu
	========================================================================== */
.side-menu {
	padding-top: 15px;
}


/* 	Other
	========================================================================== */

hr { width: 100%; }

.table-custom {
	@extend .table;
	@extend .table-hover;
	@extend .table-bordered;
	@extend .table-sm;

	col.quantity {
		width: 60px;
	}

	thead > tr > th {
		vertical-align: top;
	}

	tr > th.centre, tr > td.centre {
		text-align: center;
	}

	tr > th.right, tr > td.right {
		text-align: right;
	}

    tbody > tr > td {
		vertical-align: middle;
	}

	tbody > tr > td.high {
		height: 73px;
	}

	tbody > tr > td.centre {
		text-align: center;
	}

	tbody > tr > td.right, tbody > tr.subtotal > td.right {
		text-align: right;
		input {
			text-align: right;
		}
	}

	tfoot > tr > td.right {
		text-align: right;
	}

	tfoot > tr > td.TotalBeforeTaxModifier {
		font-weight: bold;
	}

	width: inherit;
	margin-bottom: 0;
}


/* 	Cards
	========================================================================== */
.card-header-custom {
    @include media-breakpoint-up(sm) {
        height: 65px;
        padding: 10px 3px;
    }
}


/* ==========================================================================
   Helpers
   ========================================================================== */
.current {
    @extend .active;
}

.alert {
	display: inline-block;
    margin-bottom: 0;
}

.form-message{
    @include media-breakpoint-up(sm) {
        margin-left: 15px;
    }
}

.message{
	@extend .alert;
    width: fit-content;
    @extend .mb-1;
}

// alerts
.good {
	@extend .alert-success;
}

.info {
	@extend .alert-info;
}

.warning {
	@extend .alert-warning;
}

.bad, .validation, .error {
	@extend .alert-danger;
}


/* -----------------------------------------
   Home Page
----------------------------------------- */
.top-layer-inner {

	// Reference: https://codemyviews.com/blog/inner-shadows-in-css-images-text-and-beyond
	box-shadow: 4px 4px 4px 2px $grey-600;

	h2 {
		font-size: 23px;
        margin-top: 17px
	}

	.top-comments{
		font-size: 17px;
		color: $primary;
	}

	.top-button{
		margin-top: 18px;
		margin-bottom: 25px;
		padding-right: 30px;
		padding-left: 30px;
	}

	// remove focus from button
	.btn-primary:focus{
		outline: none;
		outline-color: transparent;
	}

}

// used for search results
.IntroPage .searchResults{
	list-style: none;
}

.IntroPage .photo-layer{
	width: 100%;
	background-color: $charcoal;
	padding-top: 28px;
	padding-bottom: 28px;
}

.middle-layer {
	width: 100%;
	background-color: $primary;
    color: $white;
    padding-bottom: 20px;
    .panel-title-homepage {
        h3 {
            padding-top: 28px;
        }
    }
}

.IntroPage .boxes-layer{
	padding-top: 25px;
}

.card-homepage {
	@extend .card;
	color: inherit;
	background-color: inherit;
	border: none;
	box-shadow: none;
	border-width: 0;
	border-radius: 0;
}

.card-title-homepage {
	@extend .card-title;
	text-align: center;
    @include media-breakpoint-down(sm) {
        margin-top: 20px;
    	margin-bottom: 20px;
    }
}

.card-body-homepage {
	@extend .card-body;
	padding: inherit;
	text-align: center;
	height: 100%;
	width:100%;
}


.card-footer-homepage {
	@extend .card-footer;
	background-color: inherit;
	border: none;
	border-top-width: 0;
	background-color: $bluehaze;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	// turn off hover text-colour
	p,a { color: $body-colour;}
	display: table;
	height: 100%;
	width:100%;
}

.card-footer-homepage-row {
	display: table-row;
}

.centre-homepage {
	display: table-cell;
	text-align: center;
	margin-top: 20px;

	.mini-title {
		font-family: $headings-font-family;
		font-weight: $headings-font-weight;
		color: white;
		font-size: $font-size-h3;
	}

	.mini-statement {
		color: $primary;
	}
}

.img-responsive-fill-width {
	@extend .img-fluid;
	width: 100%;
}

.IntroPage {
	.panel-homepage:hover {
		box-shadow: 0 0 2px 2px $primary;
	}
}

// Reference: https://stackoverflow.com/questions/11679567/using-css-for-fade-in-effect-on-page-load
.fade-in {
	animation: fadein 0.2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fadeout {
	animation: fadeout 0.4s;
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}


/* 	Product Page
	========================================================================== */
.schematic{
	margin-top: 18px;
	margin-bottom: 20px;
}

.product-description-table {
	margin-top: 20px;
	margin-bottom: 20px;
}

.product-description-label-common {
	font-size: 1rem;
	letter-spacing: .3px;
	color:	$grey-600;
}

td.product-description-label {
	min-width: 125px;
	@extend .product-description-label-common;
}

.Product {
    form#AddProductForm_Form {
        @extend .row;
        p#AddProductForm_Form_error {
            @extend .col-12;
            margin-left: 12px;
            @extend .mb-3;
            width: fit-content;
        }
        fieldset {
            #Quantity_Holder {
                input {
                    margin-left: 12px;
                }
            }
        }
        .btn-toolbar {
            input {
                @extend .btn-lg;
            }
        }
    }
}


/* 	Resources Page
	========================================================================== */
.card-heading-resources-page {
    @include media-breakpoint-up(sm) {
        height: 84px;
    }
}

.card-body-resources-page {
	height: 201px;
}

.icon-resource-page {
	display: block;
	margin-top: 50px;
    @include media-breakpoint-up(sm) {
        margin-top: 30px;
    }
	line-height: 58px;
	font-size: 58px;
	text-align: center;
	color: $body-colour;
}

.ResourcessubPage aside, .EcoFriendlyBenefitsPage aside, .ServicingPage aside, .TroubleShootingPage aside, .WarrantyClaimPage aside {
    padding-top: 4px;
}

/* 	Nationwide Service Network
	========================================================================== */
.phone-number {
	width: 120px;
}


/* 	Water Filters Page
	========================================================================== */
.WaterfiltersPage {
	.brand-image {
		padding-bottom: 13px;
	}

	span.pure-filters {
		color: $brand-purify;
	}

	.col-sm-15{
        @include media-breakpoint-up(sm) {
            width:20%;
        }
	}
	.card-body-custom{
		height: 110px;
	}
}

.card-title-water-filters-page {
    @include media-breakpoint-up(sm) {
        height: 84px;
    }
}

.icon-water-filters-page {
	display: block;
	text-align: center;
	margin-top: 5px;
	color: $body-colour;
	line-height: 58px;
	font-size: 58px;
}


/* -----------------------------------------
   Warranty Claim Page
----------------------------------------- */
.WarrantyClaimPage {
    article {
    	margin-bottom: 15px;
    }
    form {
        #Form_WarrantyClaimForm_SerialNumber_Holder {
            @extend .mb-0;
        }
        #Form_WarrantyClaimForm_Message {
            height: 300px;
        }
        .btn-toolbar {
            @extend .mb-5;
        }
    }
    @include media-breakpoint-up(sm) {
        .alert {
            padding: 6px 6px;
        }
    }
}

/* -----------------------------------------
   Servicing Page
----------------------------------------- */
.ServicingPage {
    article {
    	margin-bottom: 15px;
    }
    form {
        .field.knockoutoptionset {
            @extend .row;
            margin-top: 10px;
            margin-bottom: 5px;
            label.left{
                @extend .col-2;
                @extend .pe-sm-0;
            }
            .middleColumn {
                @extend .col-sm-10;
                padding-left: 0;
                ul {
                    li {
                        label {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
        #Form_ServiceRequestForm_Visit {
            div {
                @extend .mb-3;
            }
        }
        #Form_ServiceRequestForm_Message {
            height: 300px;
        }
    }
    @include media-breakpoint-up(sm) {
        .alert {
            padding: 6px 6px;
        }
    }
}

/* -----------------------------------------
   About Page & Ecofriendly Benefits
----------------------------------------- */
.about-photo, .eco-friendly-benefits-photo {
    @include media-breakpoint-up(sm) {
        padding-top: 20px;
    }
	padding-bottom: 20px;
}

/* -----------------------------------------
   Ecofriendly Benefits
----------------------------------------- */
.hard-water-areas {
	margin-top: 30px;
	margin-bottom: 20px;
}
.calcium {
	color: #A99273;
	cursor: help;
}
.magnesium{
	color: #E8B40C;
	cursor: help;
}
.iron {
	color: #EB4261;
	cursor: help;
}
.manganese {
	color: $deeppurple;
	cursor: help;
}

/* ==========================================================================
   Contact Page - UserDefinedForm
   ========================================================================== */
.UserDefinedForm {
    .image{
        margin-bottom: 30px;
    }
    // for response
    #uff {
        @extend .mb-5;
        p {
            @extend .alert;
            @extend .alert-success;
            width: fit-content;
        }
    }

    .field.text {
      @extend .row;
      .middleColumn {
        @extend .col-sm-6;
      }
    }

    input[type="submit"] {
        @extend .btn-lg;
        min-width: 200px;
    }

    fieldset.field.userformsoptionset {
        legend {
            font-size: inherit;
        }
        .middleColumn {
            @extend .ms-2;
        }
    }

    div.textarea {
        @extend .mt-2;
    }
}

/* -----------------------------------------
   SS Shop
----------------------------------------- */
// Product Category Page
.ProductCategory .middle {
    @include media-breakpoint-up(sm) {
        margin-top: 20px;
		margin-bottom: 20px;
    }
}

.ProductCategory .middle-layer {
	margin-top: 25px;
    @include media-breakpoint-down(sm) {
		padding-bottom: 30px;
    }
}

.price-currency-button {
	margin-top: 18px;
    @include media-breakpoint-up(sm) {
        text-align: center;
        .alert {
            padding: 4px 4px;
        }
    }
}

.photo-height {
	height: 175px;
}

.productItem {
	hr {
		padding-left: 0;
		padding-right: 0;
	}
}

.productItem-inner {
	margin-top: 25px;
	margin-bottom: 25px;
}

.price-and-add {
	margin-top: 30px;
}

p.first-sentence {
	margin-bottom: 18px;
}

div.readonly {
	padding-top: 6px;
}


// Product Page
.tabs {
	margin-top: 20px;
	// scroll the tab to the right for mobile portrait view
	overflow: auto;
	.nav-tabs{
		min-width: 600px;
	}

  // Provide hover, click colour
  div {
    nav {
      div {
        a:hover {
          color: darken($primary, 20%);
        }
        a:focus {
          color: darken($primary, 40%);
        }
      }
    }
  }
}

// Cart Page
.cart-wrapper {
	display: inline-block;

	.quantityfield .quantity {
		display: block;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}

form#CartForm_CartForm .btn-toolbar {
	float: right !important;
}

.CartPage {
    .alert.alert-info, .alert.alert-danger {
        padding: 0.5rem 1rem;
    }
}


// Account Page (invoices)
table.orderhistory {
    @extend .table-responsive;
    thead {
        @extend .text-uppercase;
    }
}

.AccountPage {
    .no-addresses-found {
        width: fit-content;
    }
    .memberdetails { margin-top: 3rem; }

    .panel.radius.address-panel.even {
        margin-bottom: 1rem;
    }

    #PostalCode_Holder {
        @extend .col-sm-8;
    }

    #ShopAccountForm_EditAccountForm {
        .btn-toolbar {
            @extend .mb-5;
        }
    }
}

// CheckoutPage, AccountPage, WarrantyClaimPage, ServicingPage
.CheckoutPage, .AccountPage, .WarrantyClaimPage, .ServicingPage {
    form#Form_MembershipForm {
        .btn-toolbar {
            input {
                @extend .me-1;
                @extend .mb-1;
            }
        }
    }

    .field.text, .field.email, .field.textarea {
        @extend .form-floating;
        margin-left: 2px;
        input[type="text"], input[type="password"], input[type="email"] {
            @extend .col-sm-10;
            @extend .col-md-6;
        }
        input#SilverShop-Checkout-Component-ShippingAddress_PostalCode, input#SilverShop-Checkout-Component-ShippingAddress_Phone, input#SilverShop-Checkout-Component-BillingAddress_PostalCode, input#SilverShop-Checkout-Component-BillingAddress_Phone {
            @extend .col-sm-4;
        }
    }

    div[id*='PostalCode_Holder'].field.text, div[id*='Phone_Holder'].field.text {
        input[type="text"] {
            @extend .col-sm-5;
            @extend .col-md-3;
        }
    }
    div[id*='Address_Holder'].field.text, div[id*='AddressLine2_Holder'].field.text {
        input[type="text"] {
            @extend .col-sm-8;
            @extend .col-md-9;
        }
    }

    .field.knockoutdropdown {
        @extend .form-floating;
        margin-left: 2px;
        select {
            @extend .form-select;
            @extend .w-auto;
        }
    }

    .field.readonly {
        @extend .mb-4;
        @extend .ms-3;
    }

    #SeperateBilling_Holder {
        @extend .form-check;
        margin-top: 30px;
        margin-bottom: 25px;
        input[type="checkbox"] {
            margin-top: .25rem;
        }
        label {
            @extend .form-check-label;
        }
    }

    // Checkout Payment
    .d-table mx-auto {
        margin-top: 50px;
        @extend .mx-auto;
        display: table;
    }

    .credit-card-box .display-table {
        display: table;
        width: 100%;
    }
    .credit-card-box .display-tr {
        display: table-row;
    }
    .credit-card-box .display-td {
        display: table-cell;
        vertical-align: middle;
        width: 50%;
    }
    .icon-payment {
        font-size: 30px;
    }
    .credit-card-box {
        border-radius: 1rem;
        .card-header {
            @extend .py-2;
            @extend .px-3;
        }
    	.card-body{
            @extend .py-3;
            @extend .px-3;
    		form {
                p.bad {
                    margin-bottom: 15px;
                }
                .btn-toolbar {
                    margin-top: 25px;
                }
    		}
    	}
    }
    span.validationMessage {
        padding-left: 5px;
        font-size: 25px;
        line-height: 1;
        color: $red;
    }

    form#PaymentForm_PaymentForm {
        fieldset {
            #PaymentForm_PaymentForm_stripe_Holder {
                label { display: none; }
            }
        }
        .btn-toolbar {
            @extend .d-flex;
            input {
                @extend .flex-fill;
            }
        }
    }

    .terms-and-conditions {
    	margin-top: 50px;
    	margin-bottom: 20px;
    }
}

#PaymentForm_ConfirmationForm {
    div.btn-toolbar {
        @extend .mt-3;
    }
}

#ShippingMethodID {
    @extend .form-check;
    padding-left: 0;
    label {
        @extend .form-check-label;
    }
}

#CheckoutForm_PaymentMethodForm {
    fieldset {
        margin-bottom: 0;
    }
}

// Checkout Invoice Page - emails of invoices
table.infotable {
    background: transparent !important;
    thead, tbody {
        tr {
            th {
                @extend .text-uppercase;
                color: #495057 !important;
                background-color: #e9ecef !important;
                border-color: #dee2e6 !important;
            }
        }
    }
}

table.infotable.ordercontent {
    tfoot {
        tr {
            td.table-title {
                font-weight: bold;
            }
            td.right {
                font-weight: normal;
            }
        }
        tr.Total {
            td {
                font-size: 12px;
                text-transform: none;
            }
        }
    }
}

table#ShippingTable {
    margin-top: 0;
}

table.introduction {
    tbody {
        tr {
            td {
                img {
                    @extend .float-end;
                }
            }
        }
    }
}


/****    Login Form/Login Page/CheckoutPage  *****/
.CheckoutPage {
    #Notes {
        height: 200px;
    }
}
.logout {
    input {
        @extend .btn-lg;
    }
}

.security-login {
    p.message {
        margin-bottom: 10px;
    }
}

.login {
    margin-top: 40px;
}

#MemberLoginForm_LoginForm {
    #MemberLoginForm_LoginForm_Email_Holder, #MemberLoginForm_LoginForm_Password_Holder {
        margin-left: 0;
        @extend .form-floating;
        input[type="text"], input[type="password"] {
            @extend .col-sm-7;
            @extend .col-md-5;
        }
    }
    #MemberLoginForm_LoginForm_Remember_Holder {
        @extend .form-check;
        @extend .mt-3;
        @extend .mb-3;
        input[type="checkbox"] {
            margin-top: .25rem;
        }
        label {
            @extend .form-check-label;
        }
    }

    .btn-toolbar {
        @extend .btn-group-vertical;
        input#MemberLoginForm_LoginForm_action_doLogin {
            border-radius: 0.2rem !important;
        }
        p#ForgotPassword {
            margin-top: 19px;
        }
    }
}

/*    ------ end Main  ----   */



// Navigation - Mega Menu - ProductsPage, ResourcesPage
// Ref: https://www.codeply.com/go/vZBG452NGf/bootstrap-4-megamenu
.menu-large {
  position: static !important;
}

.megamenu {
    width: 100%;
}

.dropdown-menu.megamenu.show {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0.1rem;
}

.megamenu .dropdown-item:hover, .megamenu .dropdown-item:focus {
    background-color: transparent;
}

// Card Menu hover, focus, active
.megamenu .card:hover,
.megamenu .card:focus,
.WaterfiltersPage .card:hover,
.WaterfiltersPage .card:focus,
.TapsAccessoriesAndPartsPage .card:hover,
.TapsAccessoriesAndPartsPage .card:focus {
    border-color: $primary;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px 0.2rem rgba($primary, 1);
}
.megamenu .card:active,
.WaterfiltersPage .card:active,
.TapsAccessoriesAndPartsPage .card:active {
    border-color: shift-color($primary, 15%);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), 0 0 8px 0.2rem rgba($secondary, 1);
}

.megamenu > div > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}

@include media-breakpoint-down(sm) {
  .megamenu {
    margin-left: 0;
    margin-right: 0;
  }
  .megamenu > li {
    margin-bottom: 30px;
  }
  .megamenu > li:last-child {
    margin-bottom: 0;
  }

  .dropdown-menu.megamenu.show {
      background: transparent;
      small {
          font-size: inherit;
          font-weight: inherit;
      }
  }
}

@include media-breakpoint-down(xs) {
    .dropdown-menu.megamenu.show {
        .card-body {
            height: auto;
            min-height: inherit;
            padding: 0;
        }
    }
}

/*  end Mega Menu  */

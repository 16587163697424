// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.
// remember to remove the !default setting

/*
 * Custom Variables
 *
 * Override Bootstrap Variables with new values
 */
//
// Variables
// --------------------------------------------------
$enable-shadows: true;

//== Colors
$danger: #d93807;
$warning: #073fd9;

$charcoal:					#919b96;
$charcoal-dark:				#575d5a;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Localise for UK English
$grey-900: $gray-900;
$grey-800: $gray-800;
$grey-700: $gray-700;
$grey-600: $gray-600;
$grey-500: $gray-500;
$grey-400: $gray-400;
$grey-300: $gray-300;
$grey-200: $gray-200;
$grey-100: $gray-100;

// Translation
$grey-darker: 		$gray-800;
$grey-dark: 		$gray-700;
$grey:				$gray-500;
$grey-light:		$gray-300;
$grey-lighter: 		$gray-200;

$body-color:        $grey-dark;
$body-colour: 		$grey-dark;


$primary:               #659726;
$secondary:             #266806;
$primary-dark:	darken($primary, 7.5%);
$primary-darker:	darken($primary, 15%);
$secondary-dark: 	darken($secondary, 7.5%);
$brand-purify:	#1B9DE0;   // light blue
$brand-purify-dark:	#131E44;

$red:					#FF0000;
$orange:				#FFA500;
$green:					#008000;
$blue:					#0000FF;
$deeppurple:            #b300b3;
$brown: 				#A52A2A;
$black:					#000000;
$white:					#ffffff;
$bluehaze:				#415558;

// unselected required field  - see https://color.adobe.com/
$required:				#5a82ff; // a blue colour  // was #b22c09  - not bootstrap

// Layout Variables
$stickyFooterHeight: 460px;
$gapBetweenNavAndMain: 22px;

//== Typography
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif;
$font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
$font-family-base:        "eurostileregular", "Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base:              1rem;
$font-size-lg:                $font-size-base * 1.2;
$font-size-sm:                $font-size-base * .9;
$font-size-h1:            26px; // ~26px
$font-size-h2:            21px; // ~21px
$font-size-h3:            18px; // ~18px
$font-size-h4:            15px; // ~15p
$font-size-h5:            13px;
$font-size-h6:            12px; // ~12px

//== Headings
$headings-font-family:    "eurostileregular", Georgia, "Times New Roman", Times, serif;
$h1-font-size:                $font-size-base * 1.5;
$h2-font-size:                $font-size-base * 1.4;
$h3-font-size:                $font-size-base * 1.3;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base * 1.1;

//== Components
$border-radius:         0.15rem;
$border-radius-base:        3px;
$border-radius-lg:       4px;
$border-radius-sm:       2px;

//== Buttons
$btn-navbar-mobile-color:          #fff;
$btn-navbar-mobile-bg:             $primary-dark;
$btn-navbar-mobile-border:         $primary-dark;
$btn-link-disabled-color:          $grey-light;


//== Forms
//** Border color for inputs on focus
$input-btn-focus-color:       $primary;
$form-group-margin-bottom:      0;
$link-hover-decoration:  none;
$link-decoration: none;

//-- Z-index master list
$zindex-dropdown:            1000;

//== Navbar
//
//##

// Basics of a navbar
$navbar-light-color:             #777;
$navbar-padding-x: 5px;

// Navbar links
$navbar-light-color:                #777;
$navbar-light-hover-color:          #333;
$navbar-light-hover-bg:             transparent;
$navbar-light-active-color:         #555;
$navbar-light-active-bg:            darken($navbar-light-color, 6.5%);
$navbar-light-disabled-color:       #ccc;
$navbar-light-disabled-bg:          transparent;

// Navbar brand label
//$navbar-light-brand-color:               $navbar-light-active-color;
//$navbar-light-brand-hover-bg:            transparent;

// Navbar toggle
$navbar-light-toggler-hover-bg:           #98ff00;
$navbar-light-toggler-border-color:       #9a9a9a;


// Inverted navbar
// Reset inverted navbar basics
$navbar-dark-active-bg:             $secondary;
$navbar-dark-active-color:          darken($white, 30%);
$navbar-dark-bg:                         $primary;
$navbar-dark-border:                     darken($navbar-dark-bg, 10%);
$navbar-dark-color:                 $white;
$navbar-dark-disabled-bg:           transparent;
$navbar-dark-disabled-color:        #444;
$navbar-dark-hover-bg:              transparent;
$navbar-dark-hover-color:           darken($white, 15%);

// Inverted navbar brand label
//$navbar-dark-brand-color:                $navbar-dark-link-color;
//$navbar-dark-brand-hover-color:          #fff;
//$navbar-dark-brand-hover-bg:             transparent;

// Inverted navbar toggle
$navbar-dark-toggler-hover-bg:            #98ff00;
$navbar-dark-toggler-icon-bar-bg:         #e3e3e3;
$navbar-dark-toggler-border-color:        $white;

//=== Shared nav styles
$nav-link-padding-x:                        1rem;
$nav-link-padding-y:                        .6rem;
$nav-link-hover-color:                      $grey-lighter;
$nav-link-disabled-color:                   $grey-light;

//=== Tabs
$nav-tabs-link-active-color: darken($primary, 10%);
//--bs-nav-tabs-link-hover-color:  darken($primary, 15%);

//== Badges
$badge-font-size:  .6rem;
$badge-padding-x: 0.4rem;
$badge-font-weight: 400;

//== Type
$blockquote-font-size:        14px;

// Breadcrumbs
$breadcrumb-margin-bottom: 0.2rem;
$breadcrumb-bg: none;
$breadcrumb-item-padding: 0.2rem;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);
$breadcrumb-font-size:   .8rem;
$breadcrumb-padding-y:   .4rem;

// Input and Button active colour
$input-btn-focus-width:       .2rem;
//$input-btn-focus-color:       rgba($component-active-bg, 25%);
//$input-btn-focus-box-shadow:  0 0 8px $input-btn-focus-width $input-btn-focus-color;
//$form-check-input-focus-border:           $red;
//$form-check-input-focus-box-shadow:      $red;


// Lists
$list-group-item-padding-y: 0.5rem;
$list-group-item-padding-x: 0.5rem;
$list-group-active-bg: $primary;


// Alert
$alert-padding-y:                   .4rem;
$alert-padding-x:                   1.25rem;
$alert-margin-bottom:               .25rem;


// Card
//$card-border-width: $border-width;
//$card-border-color: rgba($black, .125);
//$card-border-radius: 1px;
$card-bg: inherit;
$card-cap-bg: inherit;
//$card-border-color: inherit;
$card-spacer-x: 0.75rem;

// Tooltip
$tooltip-bg: $brown;
